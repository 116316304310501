export const environment = {
  production: false,
  baseUrl: "https://backend.dev.p3usa.bpghub.de",
  cognitoPoolId: "us-east-1_KGex19pxa",
  cognitoWebClientId: "3f0s6225rv506h87k44kq5j0au",
  cognitoAuthDomain: "auth.dev.p3usa.bpghub.de",
  listmonkUrl: "https://newsletter.dev.p3usa.bpghub.de/",
  printformerUrl: "",
  scalerBaseUrl: "https://scaler-dev.bpghub.de/rest/api/submit-job",
  awsProjectRegion: "us-east-1",
  awsCognitoRegion: "us-east-1",
  isLocalHostOrDev: false,
  isUsa: true,
  //Rum Monitor Settings
  rumSettings: {
    applicationId: "",
    applicationVersion: "",
    applicationRegion: "",
    rumConfig: {
      sessionSampleRate: 1,
      identityPoolId: "",
      endpoint: "",
      telemetries: ["errors", "http"],
      allowCookies: true,
      enableXRay: false,
    },
  },
};
