//SelectBox Values
export const dropOptionTypesDe = [
  {
    name: "Mehrpreis",
    value: "MEHRPREIS",
  },
  {
    name: "Staffelmehrpreis",
    value: "STAFFELMEHRPREIS",
  },
  {
    name: "Stückmehrpreis",
    value: "STUECKMEHRPREIS",
  },
  {
    name: "Aufschlag Technische Kosten Festwert",
    value: "AUFSCHLAG_TECHNISCHE_KOSTEN_FESTWERT",
  },
  {
    name: "Aufschlag Technische Kosten Eingabewert",
    value: "AUFSCHLAG_TECHNISCHE_KOSTEN_EINGABEWERT",
  },
  {
    name: "Aufschlag Papierkosten Festwert",
    value: "AUFSCHLAG_PAPIERKOSTEN_FESTWERT",
  },
  {
    name: "Aufschlag Papierkosten Eingabewert",
    value: "AUFSCHLAG_PAPIERKOSTEN_EINGABEWERT",
  },
  {
    name: "Aufschlag TK+Papier Festwert",
    value: "AUFSCHLAG_STANDARDPRODUKTKOSTEN_FESTWERT",
  },
  {
    name: "Aufschlag TK+Papier Eingabewert",
    value: "AUFSCHLAG_STANDARDPRODUKTKOSTEN_EINGABEWERT",
  },
  {
    name: "Aufschlag TK+Optionen Festwert",
    value: "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_FESTWERT",
  },
  {
    name: "Aufschlag TK+Optionen Eingabewert",
    value: "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_EINGABEWERT",
  },
  {
    name: "Aufschlag Gesamtpreis Festwert",
    value: "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_FESTWERT",
  },
  {
    name: "Aufschlag Gesamtpreis Eingabewert",
    value: "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_EINGABEWERT",
  },
];

export const dropOptionTypesEn = [
  {
    name: "Additional",
    value: "MEHRPREIS",
  },
  {
    name: "Scale additional",
    value: "STAFFELMEHRPREIS",
  },
  {
    name: "Unit additional",
    value: "STUECKMEHRPREIS",
  },
  {
    name: "Surcharge technical costs fixed value",
    value: "AUFSCHLAG_TECHNISCHE_KOSTEN_FESTWERT",
  },
  {
    name: "Surcharge technical costs input value",
    value: "AUFSCHLAG_TECHNISCHE_KOSTEN_EINGABEWERT",
  },
  {
    name: "Surcharge paper costs fixed value",
    value: "AUFSCHLAG_PAPIERKOSTEN_FESTWERT",
  },
  {
    name: "Surcharge paper costs input value",
    value: "AUFSCHLAG_PAPIERKOSTEN_EINGABEWERT",
  },
  {
    name: "Surcharge technical costs+paper fixed value",
    value: "AUFSCHLAG_STANDARDPRODUKTKOSTEN_FESTWERT",
  },
  {
    name: "Surcharge technical costs+paper input value",
    value: "AUFSCHLAG_STANDARDPRODUKTKOSTEN_EINGABEWERT",
  },
  {
    name: "Surcharge technical costs+options fixed value",
    value: "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_FESTWERT",
  },
  {
    name: "Surcharge technical costs+options input value",
    value: "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_EINGABEWERT",
  },
  {
    name: "Surcharge total price fixed value",
    value: "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_FESTWERT",
  },
  {
    name: "Surcharge total price input value",
    value: "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_EINGABEWERT",
  },
];

export const dropOptionPositionDe = [
  {
    name: "im Gesamtpreis",
    value: "GESAMTPREIS",
  },
  {
    name: "separat ausweisen",
    value: "OPTIONAL",
  },
];

export const dropOptionPositionEn = [
  {
    name: "in total price",
    value: "GESAMTPREIS",
  },
  {
    name: "report separately",
    value: "OPTIONAL",
  },
];

//  For US

export const dropOptionInvoiceCategoryUs = [
  {
    name: "Textprep",
    value: "TEXTPREP",
  },
  {
    name: "Textprint",
    value: "TEXTPRINT",
  },
  {
    name: "Bind",
    value: "BIND",
  },
  {
    name: "Option Group",
    value: "OPTION_GROUP",
  },
];

export const dropOptionTypesUs = [
  {
    name: "Cartons (Qty/Unit: 1000)",
    value: "CARTONS",
  },
  {
    name: "Difference (Qty/Unit: 1000)",
    value: "DIFFERENCE",
  },
  {
    name: "Inserts (Qty/Unit: 1000)",
    value: "INSERTS",
  },
  {
    name: "One-time Prices (Qty/Unit: 1)",
    value: "ONE_TIME_PRICES",
  },
  {
    name: "One-time Prices (Qty/Unit: 1000)",
    value: "ONE_TIME_PRICES_1000",
  },
  {
    name: "Surcharge (Qty/Unit: 1)",
    value: "SURCHARGE",
  },
  {
    name: "Sub-Surcharge (Qty/Unit: 1)",
    value: "SUB_SURCHARGE",
  },
];

export const glCodes = [
  {
    priceList: "Simon & Schuster",
    codes: [
      {
        name: "TEXTPREP Prep (462070)",
        value: "462070",
      },
      {
        name: "TEXTPREP Plates (454025)",
        value: "454025",
      },
      {
        name: "TEXTPRINT Makeready (454010)",
        value: "454010",
      },
      {
        name: "TEXTPRINT Run/M (454010)",
        value: "454010",
      },
      {
        name: "BIND Makeready (454030)",
        value: "454030",
      },
      {
        name: "BIND Run/M (454030)",
        value: "454030",
      },
      {
        name: "CARTONS Pack/Carton (454100)",
        value: "454100",
      },
      {
        name: "CARTONS CTN Label (454100)",
        value: "454100",
      },
      {
        name: "FREIGHT Freight (454200)",
        value: "454200",
      },
      {
        name: "Without GL-Code",
        value: "0",
      },
    ],
  },
  {
    priceList: "Harper Collins",
    codes: [
      {
        name: "TEXTPREP Plates (9305)",
        value: "9305",
      },
      {
        name: "TEXTPRINT Print (0201)",
        value: "0201",
      },
      {
        name: "BIND Bind (0301)",
        value: "0301",
      },
      {
        name: "CARTONS CTN Label (0303)",
        value: "0303",
      },
      {
        name: "PALLETS Pallets (0301)",
        value: "0301",
      },
      {
        name: "FREIGHT Freight (0401)",
        value: "0401",
      },
      {
        name: "Without GL-Code",
        value: "0",
      },
    ],
  },
  {
    priceList: "Harlequin Enterprises",
    codes: [
      {
        name: "TEXTPREP Plates (9305)",
        value: "9305",
      },
      {
        name: "TEXTPRINT Print (0201)",
        value: "0201",
      },
      {
        name: "BIND Bind (0301)",
        value: "0301",
      },
      {
        name: "BIND Palleting (0301)",
        value: "0301",
      },
      {
        name: "CARTONS CTN Label (0303)",
        value: "0303",
      },
      {
        name: "PALLETS Pallets (0301)",
        value: "0301",
      },
      {
        name: "FREIGHT Freight (0401)",
        value: "0401",
      },
      {
        name: "Without GL-Code (0)",
        value: "0",
      },
    ],
  },
  {
    priceList: "MacMillan",
    codes: [
      {
        name: "TEXTPREP Plates (50100000)",
        value: "50100000",
      },
      {
        name: "TEXTPRINT Makeready (50100000)",
        value: "50100000",
      },
      {
        name: "TEXTPRINT Run/M (50100000)",
        value: "50100000",
      },
      {
        name: "BIND Print & Bind (50100000)",
        value: "50100000",
      },
      {
        name: "CARTONS Carton/Pack (50120000)",
        value: "50120000",
      },
      {
        name: "CARTONS CTN Sticker (50120000)",
        value: "50120000",
      },
      {
        name: "PALLETS Pallets (62730000)",
        value: "62730000",
      },
      {
        name: "FREIGHT Freight (62730000)",
        value: "62730000",
      },
      {
        name: "Without GL-Code",
        value: "0",
      },
    ],
  },
  //Only for LocalHost necessary
  {
    priceList: "Harper Collins (alt)",
    codes: [
      {
        name: "Prep (000462070)",
        value: "000462070",
      },
      {
        name: "Plates (454025)",
        value: "454025",
      },
      {
        name: "Makeready (454010)",
        value: "454010",
      },
      {
        name: "Run/M (454010)",
        value: "454010",
      },
      {
        name: "Makeready (454030)",
        value: "454030",
      },
      {
        name: "Run/M (454030)",
        value: "454030",
      },
      {
        name: "Pack/Carton (454100)",
        value: "454100",
      },
      {
        name: "CTN Label (454100)",
        value: "454100",
      },
      {
        name: "Freight (454200)",
        value: "454200",
      },
      {
        name: "Without GL-Code",
        value: "0",
      },
    ],
  },
  {
    priceList: "DEFAULT",
    codes: [
      {
        name: "Without GL-Code",
        value: "0",
      },
    ],
  },
];

export const quantityType = [
  {
    name: "Page Count",
    value: "PAGE_COUNT",
  },
  {
    name: "Order Quantity",
    value: "ORDER_QUANTITY",
  },
];

export const dropRequiredQuantityType = [
  {
    name: "Option Value",
    value: "OPTION_VALUE",
  },
  {
    name: "Shipped Quantity",
    value: "SHIPPED_QUANTITY",
  },
];
