import { Produkt, PreislistenKatalog } from "./produkt";
import { Option } from "./option";
import { OptionPriceInformation } from "./optionPriceInformation";
import { OptionGroup } from "./optionGroup";

export class OptionFormData {
  //  both
  id = "";
  optionName = "";
  optionType: string;
  sortId = 0;
  optionPriceInformation: OptionPriceInformation[];
  preislistenKatalog: PreislistenKatalog;
  optionGroup = <OptionGroup>{};
  associatedProducts: Produkt[];

  //  DE
  optionPosition: string;
  //  only on certain Option Types
  optionUnitId?: string;
  fixedValue: number;
  //  US
  invoiceCategory: string;
  glCode: string;
  quantityType: string;
  requiredQuantityType: string;
  ancillaryCode: string;

  optionPriceInformationColumnNames: string[] = [];
  dependentOptions: Option[];
  hideInFrontend: boolean;

  constructor(option?: Option) {
    if (option) {
      this.optionName = option.optionName + "";
      this.optionType = option.optionType + "";
      if (option.optionPosition) this.optionPosition = option.optionPosition;
      this.sortId = option.sortId;
      if (option.optionPriceInformation)
        this.optionPriceInformation = option.optionPriceInformation;
      if (option.preislistenKatalog)
        this.preislistenKatalog = option.preislistenKatalog;
      if (option.optionUnit && option.optionUnit.id)
        this.optionUnitId = option.optionUnit.id;
      if (option.optionGroup) this.optionGroup = option.optionGroup;
      if (option.fixedValue) this.fixedValue = option.fixedValue;
      this.id = option.id;
      this.associatedProducts = [];
      this.invoiceCategory = option.invoiceCategory;
      this.glCode = option.glCode;
      this.requiredQuantityType = option.requiredQuantityType;
      this.quantityType = option.quantityType;
      if (option.cartonColumnNames) {
        this.optionPriceInformationColumnNames = option.cartonColumnNames;
      }
      if (option.ancillaryCode) {
        this.ancillaryCode = option.ancillaryCode;
      }
      this.associatedProducts = [];
      this.hideInFrontend = option.hideInFrontend;
    }
  }
}
