import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  CalculationTableConfig,
  CalculatorOption,
  CalculatorRequest,
  CalculatorUsFormData,
  CalculatorUsFormDropdownValues,
  CalculatorUsPdfRequest,
  TrimSize,
  TrimSizeRuleType,
  TrimSizeValidation,
  TrimSizeValidationResponse,
} from "./calculator-us-formData";
import { UserService } from "../../../services/user.service";
import { CalculatorService } from "../../../services/calculator.service";

import { OrganizationService } from "../../../services/organization.service";
import { compareStringsAlphabeticallyIgnoreCase } from "../../../utils/stringUtils";
import {
  PdfQuoteNumber,
  UsCalculatorResult,
} from "../../../model/calculation/calculationResult";
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Publisher } from "../../../model/organization/organization";
import { ProduktService } from "../../../services/produkt.service";
import { DxFormComponent } from "devextreme-angular";
import { Produkt } from "../../../model/calculation/produkt";
import { Subscription } from "rxjs";
import { OptionService } from "../../../services/option.service";
import { Option } from "../../../model/calculation/option";
import notify from "devextreme/ui/notify";
import { OptionGroup } from "../../../model/calculation/optionGroup";
import { AdditionalSpecificationListComponent } from "./additional-specification-list/additional-specification-list.component";
import { PriceScaleEntryService } from "../../../services/price-scale-entry.service";

@Component({
  selector: "app-calculator-us",
  templateUrl: "./calculator-us.component.html",
  styleUrls: ["./calculator-us.component.scss"],
})
export class CalculatorUsComponent implements OnInit, OnDestroy {
  formatMessage = formatMessage;
  json = JSON;
  @ViewChild(DxFormComponent) form: DxFormComponent;
  @ViewChild(AdditionalSpecificationListComponent)
  additionalSpecificationList: AdditionalSpecificationListComponent;

  calculatorUsFormDropdownValues = new CalculatorUsFormDropdownValues();
  calculationData: CalculatorUsFormData = <CalculatorUsFormData>{};
  organizations: Publisher[];
  selectedPublisher: Publisher;
  products: Produkt[];
  selectedProduct: Produkt;

  calculationResponseSubscription: Subscription;
  calculationResponse: UsCalculatorResult;
  calculationTableConfig: CalculationTableConfig = new CalculationTableConfig(
    false,
    false,
    false
  );
  quantities: number[];

  usedCartonPackOption: Option | any;
  usedCartonPackOptionGroup: any;
  usedCartonCalculatorOption: CalculatorOption;
  selectedCartonPack: string;

  allMappingProperties: Option[];

  showAdditionalSpecifications: boolean;
  additionalOptionGroups: OptionGroup[];
  additioalCalculatorOptions: CalculatorOption[];
  additionalSpecButtonDisabled: boolean = true;

  responseAncillaryGroupTypes: any;

  pageDropdownDataSource: number[];

  // Width Height Validation variables
  widthValidationMessage = "";
  heightValidationMessage = "";
  trimSizeValidation: TrimSizeValidationResponse;

  constructor(
    public calculatorService: CalculatorService,
    private userService: UserService,
    private produktService: ProduktService,
    private organizationService: OrganizationService,
    private http: HttpClient,
    private optionService: OptionService,
    private priceScaleService: PriceScaleEntryService
  ) {
    this.publisherSelected = this.publisherSelected.bind(this);
    this.productSelected = this.productSelected.bind(this);
    this.resetAutoFilledValues = this.resetAutoFilledValues.bind(this);
    this.validateWidth = this.validateWidth.bind(this);
    this.validateHeight = this.validateHeight.bind(this);

    this.calculationResponseSubscription = this.calculatorService
      .getCalculationResponse()
      .subscribe((response) => {
        this.calculationResponse = response;
      });
  }

  ngOnDestroy(): void {
    this.calculationResponseSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.organizationService.findAllPublisher().subscribe((org) => {
      if (org) {
        org.sort((a, b) =>
          compareStringsAlphabeticallyIgnoreCase(a.displayName, b.displayName)
        );
        if (org.length == 1) this.selectedPublisher = org[0];
        this.organizations = org;
      }
    });
  }

  getTodaysDate() {
    return new Date().toLocaleDateString("en-US");
  }

  publisherSelected($event: any) {
    this.resetAutoFilledValues();
    // this.selectedProduct = undefined throws error
    this.setProduct(undefined);
    this.selectedPublisher = $event.value;
    this.calculatorService
      .getPublisherByIdNoProduct(this.selectedPublisher.id)
      .subscribe((publisher) => {
        if (publisher?.preislistenKatalog?.produktList) {
          this.products = [...publisher.preislistenKatalog.produktList];
        }
      });
  }

  productSelected($event: any) {
    let formData = this.form?.instance?.option("formData");
    formData.pressMethod = undefined;
    formData.binding = undefined;

    if ($event.value) {
      // reset values if product changes
      if ($event.previousValue != $event.value) {
        this.resetAutoFilledValues();
      }
      this.produktService
        .getUsaProduct($event.value.id)
        .subscribe((product) => {
          if (product) {
            this.setBinding(product);
            formData.edition = product.edition;
            formData.reprint = product.reprint;
            //TODO: Derzeit nicht über Frontend möglich für (muss mit Julia abgesprochen werden):
            this.calculationData.trimSizeGroup =
              product.width + " x " + product.height;
            //Product.twoTitles is boolean but request needs Integer
            this.calculationData.numberOfTitles = product.twoTitles ? 2 : 1;
          }
        });

      this.optionService
        .findOptionGroupsByPreislistenKatalog(
          this.selectedPublisher.preislistenKatalog!
        )
        .subscribe((optionGroups) => {
          this.usedCartonPackOptionGroup = optionGroups.find(
            (optionGroup) =>
              optionGroup.groupName == "Cartoning/Palletizing" ||
              optionGroup.groupName == "Cartons/Cartoning"
          );
          if (this.usedCartonPackOptionGroup) {
            this.optionService
              .findOptionsByOptionGroup(this.usedCartonPackOptionGroup)
              .subscribe((options) => {
                if (options) {
                  const foundOption = options.find((option) => {
                    return (
                      option &&
                      option?.associatedProducts.includes(
                        this.selectedProduct.id
                      ) &&
                      !option.hideInFrontend
                    );
                  });
                  if (foundOption) {
                    this.usedCartonPackOption = { ...foundOption };
                    this.handleNotCartonsOption();
                  } else {
                    this.displayError(
                      "calculator.calculation.us.cartonpackoption.notfound",
                      this.selectedProduct.name
                    );
                  }
                }
              });
          } else {
            this.displayError(
              "calculator.calculation.us.optiongroup.notfound",
              this.selectedPublisher.name
            );
          }

          this.additionalOptionGroups = optionGroups.filter((optionGroup) => {
            return (
              optionGroup.groupName != "Cartoning/Palletizing" &&
              optionGroup.groupName != "Cartons/Cartoning"
            );
          });
        });
      this.optionService
        .getOptionsByPreislistenKatalog(
          this.selectedPublisher.preislistenKatalog!
        )
        .subscribe((options) => {
          if (options) this.allMappingProperties = [...options];
        });

      this.priceScaleService
        .findAllPagesInPriceScaleByProductId(this.selectedProduct.id)
        .subscribe((pages) => {
          const pageCopy = [...pages];
          this.pageDropdownDataSource = pageCopy.sort((a, b) => a - b);
        });
      this.getTrimSizeValidations();
    }
  }

  calculate() {
    this.quantities = [];

    if (!this.form?.instance.validate().isValid) {
      return;
    }

    this.calculationData.selectedOptions = this.additioalCalculatorOptions
      ? [this.usedCartonCalculatorOption, ...this.additioalCalculatorOptions]
      : [this.usedCartonCalculatorOption];

    const calculationDataCopy = { ...this.calculationData };

    const calculationRequest: CalculatorRequest = new CalculatorRequest(
      calculationDataCopy,
      this.selectedPublisher.id
    );

    console.log(calculationRequest);

    this.http
      .post<UsCalculatorResult>(
        `${environment.baseUrl}/api/calculator/us/calculate`,
        calculationRequest // Daten der Calculation
      )
      .toPromise()
      .then((data) => {
        this.calculatorService.sendCalculationResponse(data);
        // When status success
        if (data.status === "success") {
          // Then exclude 0 Values
          // IMPORTANT: Only enter edit-Response if success -> else Request 400 Error
          this.http
            .post<UsCalculatorResult>(
              `${environment.baseUrl}/api/calculator/us/edit-response`,
              data // daten der response
            )
            .subscribe((data) => {
              this.calculatorService.sendCalculationResponse(data);
              this.prepareResponseTable(data);

              // Quantity Array for the table headline
              this.quantities = JSON.parse(
                JSON.stringify(this.calculationData.quantity)
              );
            });
        }
        // when error
        else {
          this.calculationTableConfig = new CalculationTableConfig(
            false,
            false,
            false
          );
        }
      });
  }

  private prepareResponseTable(data: UsCalculatorResult) {
    let calcData = data.data;
    this.calculationTableConfig.responseSuccessful = true;

    this.responseAncillaryGroupTypes = data.data.ancillaryItemGroupList[0]
      .ancillaryItemList!.filter(
        (ancillaryItem) =>
          ancillaryItem.invoiceItemAncillaryType == "SUMMARY" &&
          ancillaryItem.invoiceItemAncillaryCode != "SUMMARY_TOTAL"
      )
      .map((value) => {
        return value.ancillaryTypeName == "OPTION_GROUP"
          ? { value: value.ancillaryTypeName, name: value.ancillaryCodeName }
          : {
              value: value.ancillaryTypeName,
              name: formatMessage(
                "calculator.calculation.us.response.headLineText." +
                  value.ancillaryTypeName.toLowerCase()
              ),
            };
      });

    // Überprüfung für das zweite Objekt in ancillaryItemGroupList
    this.calculationTableConfig.showSecondCol = !!(
      calcData.ancillaryItemGroupList &&
      calcData.ancillaryItemGroupList[1] &&
      calcData.ancillaryItemGroupList[1].ancillaryItemList &&
      calcData.ancillaryItemGroupList[1].ancillaryItemList.length > 0
    );

    // Überprüfung für das dritte Objekt in ancillaryItemGroupList
    this.calculationTableConfig.showThirdCol = !!(
      calcData.ancillaryItemGroupList &&
      calcData.ancillaryItemGroupList[2] &&
      calcData.ancillaryItemGroupList[2].ancillaryItemList &&
      calcData.ancillaryItemGroupList[2].ancillaryItemList.length > 0
    );
  }

  setProduct(product) {
    this.selectedProduct = product;
  }
  private resetAutoFilledValues() {
    if (this.selectedProduct) {
      const formData = this.form?.instance?.option("formData");
      formData.edition = undefined;
      formData.binding = undefined;
      formData.reprint = undefined;
      formData.pressMethod = undefined;
      this.usedCartonPackOption = undefined;
      this.selectedCartonPack = "";
      this.calculationData.selectedOptions = [];
    }
  }

  // TODO : Match MM Rules with Backend Trimsize rules
  private setBinding(product: Produkt) {
    const form = this.form?.instance?.option("formData");
    //Mass Market
    if (product.edition === "MM") {
      //Rack
      if (
        product.width == 4.125 &&
        (product.height == 6.75 || product.height == 6.625)
      ) {
        form.binding = "MMR";
      }
      //Premium
      if (product.width == 4.125 && product.height == 7.5) {
        form.binding = "MMP";
      }
      //Digest
      if (product.width == 5.1875 && product.height == 7.625) {
        form.binding = "MMD";
      }
      this.setPressMetod(product.produktType);
    } else {
      //Trade Market
      form.binding = product.produktType;
    }
  }

  private setPressMetod(produktType: string) {
    this.calculationData.pressMethod = produktType;
  }

  onCartonPackSelected(event: any) {
    if (event && this.usedCartonPackOption) {
      const mappingProperties = this.allMappingProperties.find(
        (option) => option.id == this.usedCartonPackOption.id
      )?.optionMappingProperties;
      if (mappingProperties) {
        this.usedCartonCalculatorOption = {
          optionId: this.usedCartonPackOption.id,
          optionValue: event.selectedItem.replace(/\D/g, ""),
          specification: mappingProperties.specification,
          wildcard: mappingProperties.wildcard,
        };
      }
    }
  }

  displayError(key: string, addition?: string) {
    if (addition) {
      notify(
        {
          message: formatMessage(key, addition),
          type: "error",
          displayName: 3500,
        },
        { position: "bottom center", direction: "up-stack" }
      );
    } else {
      notify(
        {
          message: formatMessage(key),
          type: "error",
          displayName: 3500,
        },
        { position: "bottom center", direction: "up-stack" }
      );
    }
  }

  onCartonPackFocusIn() {
    if (!this.usedCartonPackOptionGroup && this.selectedProduct) {
      this.displayError(
        "calculator.calculation.us.optiongroup.notfound",
        this.selectedPublisher.name
      );
    }
    if (
      !this.usedCartonPackOption &&
      this.selectedProduct &&
      this.usedCartonPackOptionGroup &&
      this.usedCartonPackOption.optionType == "CATONS"
    ) {
      this.displayError(
        "calculator.calculation.us.cartonpackoption.notfound",
        this.selectedProduct.name
      );
    }
  }

  submitOptionen() {
    if (this.additionalSpecificationList.checkIfInputMissing()) {
      return;
    }

    this.showAdditionalSpecifications = false;
    let selectedOptions: Option[] = [];
    this.additioalCalculatorOptions = [];
    this.additionalSpecificationList.list.selectedItems.forEach(
      (group) => (selectedOptions = [...selectedOptions, ...group.items])
    );

    console.log(selectedOptions);
    selectedOptions.forEach((option) => {
      // TODO: why is there a mapping property check??
      const mappingProperties = this.allMappingProperties.find(
        (mappingOption) => option.id == mappingOption.id
      )?.optionMappingProperties;
      if (mappingProperties) {
        const isOtpAndOptionValue: boolean =
          option.optionType == "ONE_TIME_PRICES_1000" ||
          option.optionType == "ONE_TIME_PRICES";

        const calculatorOption: CalculatorOption = {
          optionId: option.id,
          optionValue: isOtpAndOptionValue
            ? this.calculationData.quantity[0]
            : option.userInput,
          specification: mappingProperties.specification,
          wildcard: mappingProperties.wildcard,
        };

        this.additioalCalculatorOptions.push(calculatorOption);
      }
    });
  }

  onSelelectOptions($event: Option[]) {
    this.showAdditionalSpecifications = false;
  }

  onAddSpecButtonSelected() {
    this.showAdditionalSpecifications = true;
  }

  private handleNotCartonsOption() {
    if (
      this.usedCartonPackOption.optionType == "ONE_TIME_PRICES" ||
      this.usedCartonPackOption.optionType == "ONE_TIME_PRICES_1000"
    ) {
      const mappingProperties = this.allMappingProperties.find(
        (option) => option.id == this.usedCartonPackOption.id
      )?.optionMappingProperties;
      if (mappingProperties) {
        this.usedCartonCalculatorOption = {
          optionId: this.usedCartonPackOption.id,
          optionValue: 0,
          specification: mappingProperties.specification,
          wildcard: mappingProperties.wildcard,
        };
      }
    }
  }

  onFormFieldChanged() {
    const form = this.form?.instance?.option("formData");
    if (form?.quantity) {
      this.additionalSpecButtonDisabled = !(
        this.selectedProduct &&
        !(form?.quantity[0] == undefined || form?.quantity[0] == "")
      );
    }
  }

  private async preparePdfRequestData() {
    console.log(this.form);
    // TODO: Integrate Quote-Number to JSON
    const quoteNumber: string = await this.getQuoteNumber();
    const formData = this.form?.instance?.option("formData");
    let pdfRequestData: CalculatorUsPdfRequest = {
      ...this.calculationResponse,
      generalInformation: {
        author: formData.author,
        title: formData.title,
        customer: formData.customer,
        customerContact: formData.customerContact,
        date: new Date().toLocaleDateString(),
        quoteNumber: 0,
        bpgContact: formData.bpgContact,
      },
      jobSpecification: {
        quantity: formData.quantity,
        trimSize: formData.trimSizeGroup,
        pages: formData.pages,
        prep: formData.prep,
        proofs: formData.proofText,
        plates: formData.plates,
        paper: formData.paperText,
        print: formData.printText,
        bind: formData.bind,
        pack: formData.pack,
      },
    };
    console.log(pdfRequestData);
  }

  private async getQuoteNumber() {
    const quoteNumberWrapper = await this.http
      .get<PdfQuoteNumber>(
        `${environment.baseUrl}/api/calculator/us/quote-number`
      )
      .toPromise();
    return quoteNumberWrapper.quoteNumber;
  }

  createQuoteAsPdf() {
    this.preparePdfRequestData();

    //send to scaler
  }

  getTrimSizeValidations() {
    const param = new HttpParams().set("productId", this.selectedProduct.id);
    this.http
      .get<TrimSizeValidationResponse>(
        `${environment.baseUrl}/api/calculator/us/validate-trim-size/${this.selectedPublisher.id}`,
        { params: param }
      )
      .toPromise()
      .then((response) => {
        this.trimSizeValidation = response;
      });
  }

  validateWidth(): boolean {
    this.widthValidationMessage = this.getValidationMessage(
      this.trimSizeValidation?.width
    );
    return this.compareTrimSizeRuleToInput(
      Number(this.calculationData.width),
      this.trimSizeValidation?.width
    );
  }

  validateHeight(): boolean {
    this.heightValidationMessage = this.getValidationMessage(
      this.trimSizeValidation?.height
    );
    return this.compareTrimSizeRuleToInput(
      Number(this.calculationData.height),
      this.trimSizeValidation?.height
    );
  }

  compareTrimSizeRuleToInput(
    userInput: number,
    validator: TrimSizeValidation
  ): boolean {
    switch (validator?.validationRule) {
      case TrimSizeRuleType.HEIGHT_GREATER_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.WIDTH_GREATER_THAN_OR_EQUAL_TO:
        return userInput >= validator.comparer;
      case TrimSizeRuleType.HEIGHT_LESS_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.WIDTH_LESS_THAN_OR_EQUAL_TO:
        return userInput <= validator.comparer;
      case TrimSizeRuleType.HEIGHT_GREATER_THAN:
        return userInput > validator.comparer;
      case TrimSizeRuleType.WIDTH_EQUAL_TO:
        return userInput == validator.comparer;
      default:
        return false;
    }
  }

  getValidationMessage(validator: TrimSizeValidation): string {
    switch (validator?.validationRule) {
      case TrimSizeRuleType.HEIGHT_GREATER_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.WIDTH_GREATER_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.HEIGHT_LESS_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.WIDTH_LESS_THAN_OR_EQUAL_TO:
      case TrimSizeRuleType.HEIGHT_GREATER_THAN:
      case TrimSizeRuleType.WIDTH_EQUAL_TO:
        return formatMessage(
          `calculator.calculation.validation.${validator.validationRule}`,
          validator.comparer.toString()
        );
    }
  }
}
